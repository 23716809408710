


















import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class checkSsnForBorrower extends Vue {
  private users = [];
  closeModal() {
    this.$modal.hide("modalForCheckSsn");
    this.$emit("hideModal");
  }

  updateSsn(userInfo){
      this.$emit('callMountFunction',userInfo);
      this.closeModal()
  }

  showModal(data) {
    this.users = data;
    this.$modal.show("modalForCheckSsn");
  }

  mounted() {}
}

















































































































































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { BASE_API_URL } from "@/config";

import Axios from "axios";

@Component({})
export default class LosRequiredField extends Vue {
  @Prop()
  public losRequiredList;
  @Prop()
  public exportData;
  @Prop()
  public ausRequest;
  @Prop()
  public tabErrors;
  @Prop()
  public tabName;
  @Prop()
  public noSkip;
  @Prop()
  public isComingFromWemloLeadPage;
  @Prop({ default: false })
  public skipUrlaValidation;
  @Prop({ default: false })
  public areLinksDisabled;

  public gotoSubmissionForm() {
    this.$router.push({
      path: this.$getCurrentUserTypePath("submission-form"),
      query: {
        id: this.$route.query.id,
        edit: "true",
      },
    });
  }

  redirectToTab(tabName = null, borrowerAndCoBorrowerIndex = null) {
    if (this.areLinksDisabled) {
      return;
    }

    if (this.isComingFromWemloLeadPage) {
      if (tabName) {
        this.$router.push({
          path: this.$getCurrentUserTypePath("current-urla"),
          hash: "#" + tabName,
          query: {
            id: this.$route.query.id,
            requiredFields: "true",
            index:borrowerAndCoBorrowerIndex?borrowerAndCoBorrowerIndex:null,
          }
        });
      }
    } else {
      this.$emit('callMountFunction',null,tabName,true,borrowerAndCoBorrowerIndex)
    }
  }

  mounted() {
    if (this.losRequiredList) {
      this.$modal.show("showLosRequiredField");
    }
    if (this.tabErrors && this.tabErrors.length > 0) {
      if (
        this.tabName == "General" ||
        this.tabName == "LoanAndProperty" ||
        this.tabName == "Transaction"
      )
        this.$modal.show("showTabRequiredList");
      else this.$modal.show("showBorrowerRequiredList");
    }
  }
}

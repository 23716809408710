


import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "@/config";
import PreRequisiteHeader from "@/views/preRequisiteHeader/PreRequisiteHeader.vue";
import LosRequiredField from "@/views/LosRequiredField.vue";

import { mixins } from "vue-class-component";
import CommonMixin from "@/mixins/common.mixin";
import BootstrapVue from "bootstrap-vue";
import { ALLOWED_FILE_EXTENSIONS } from "@/constants";
import draggable from "vuedraggable";
import DisplayFiles from "@/views/DisplayDocument.vue";
import saveAs from "save-as";
import checkSsn from "@/views/checkSsnForBorrower.vue";
import _ from "lodash";

Vue.use(BootstrapVue);

@Component({
  components: {
    PreRequisiteHeader,
    draggable,
    DisplayFiles,
    LosRequiredField,
    checkSsn,
  },
})
export default class ComplianceListComponent extends mixins(CommonMixin) {
  public allowedFileExtensions = ALLOWED_FILE_EXTENSIONS;
  @Prop() sourceType;
  @Prop({ default: [] }) complianceList: any[];
  @Prop({default: false}) public loanIsLocked: boolean;
  public selectedCompliance: any[] = [];
  public searchQuery: string = "";
  public shownDocumentId: string = "";
  public generatingDisclosuresFile: boolean = false;
  public isMismo: boolean = null;
  public losRequiredList: any = null;
  public isLosFieldMissing = false;
  public exportCallback: any = null;

  public isComplianceSelected(compliance) {
    return this.selectedComplianceIds.includes(compliance.categoryName);
  }

  isCustomFile(compliance){
    return (compliance.categoryName == 'All Communication on the File' || compliance.categoryName == 'All Conditions Sent to Lender')
  }

  public getDocumentStatus(categoryName, documents) {
    if (!documents || !Array.isArray(documents)) {
      if(categoryName == 'All Communication on the File' || categoryName == 'All Conditions Sent to Lender'){
        return "Review Document";
      }
      return null;
    }
    let fileHistory = documents[documents.length - 1];
    if (fileHistory) {
      return (!fileHistory.status || fileHistory.status == "Uploaded")
        ? "Review Document"
        : fileHistory.status;
    }
  }
  get selectedComplianceIds() {
    return this.selectedCompliance.map((c) => c.categoryName);
  }

  async uploadFile(event, categoryName) {
    if (event.target.files.length > 0) {
      let loanTxnId = this.$route.query.id;
      this.$store.state.wemloLoader = true;
      let formData = new FormData();
      formData.append("loanTxnId", String(loanTxnId));
      formData.append("uploadFile", event.target.files[0]);
      formData.append("categoryName", categoryName);
      formData.append("userId", this.$userId);
      let response = await Axios.post(
        BASE_API_URL + "compliance/uploadComplianceDoc",
        formData);
      if (response && response.data && response.data.success) {
        this.$snotify.success("file Uploaded Successfully.");
        this.$emit("fetchCompliance");
      } else {
        this.$snotify.error("Error in uploading file.");
      }
      this.$store.state.wemloLoader = false;
    }
  }

 public async generateOnDemandCompliance(compliance) {
   try {
      let loanTxnId = this.$route.query.id;
      this.$store.state.wemloLoader = true;
     let response = await Axios.post(
          BASE_API_URL + "compliance/generateOnDemandCompliance",
          { loanTxnId, document : [compliance.categoryName] });
       if (response.status == 200 && response.data) {
      this.$snotify.success("Generated document Successfully.");
      this.$emit("fetchCompliance");
    } else {
      this.$snotify.error("Error in generating document.");
    }
    this.$store.state.wemloLoader = false;
   } catch (error) {
     console.log('generateOnDemandCompliance',error)
      this.$store.state.wemloLoader = false;
   }
 }


  public async generateBrokerDisclosuresFile(compliance) {
    this.generatingDisclosuresFile = true;
    const loanTxnId = this.$route.query.id;

    try {
      const { data: { message, compliance: newCompliance } } = await Axios.post(
        BASE_API_URL + "disclosure/processComplianceBrokerDisclosures",
        { loanTxnId, compliance });

      this.$snotify.success(message); 

      this.complianceList = [
        newCompliance,
        ...this.complianceList.filter(compliance => compliance.categoryName !== newCompliance.categoryName)
      ];

      this.generatingDisclosuresFile = false;
    } catch (error) {
      this.generatingDisclosuresFile = false;
    }

  }

  get isAllSelected(){
    return this.selectedCompliance.length  == this.complianceList.length;
  }

  public selectAllDocuments(){
    if(this.isAllSelected){
      this.selectedCompliance = []  
    }else{
      this.selectedCompliance = _.cloneDeep(this.complianceList);
    }
  }

   public async downloadCompliancePackage() {
    try {
      let loanTxnId = this.$route.query.id;
      this.$store.state.wemloLoader = true;
      let response = await Axios.post(
        BASE_API_URL + "compliance/downloadCompliancePackage",
        { loanTxnId: loanTxnId,
          selections : this.selectedComplianceIds
        },
        { responseType: "blob" }
      );
      if (response.status == 200 && response.data) {
        saveAs(response.data, loanTxnId + `.zip`);
      } else {
        this.$snotify.clear();
        this.$snotify.error("No Data Found");
      }
      this.$store.state.wemloLoader = false;
    } catch (error) {
      this.$store.state.wemloLoader = false;
    }
  }

  // public async downloadSelectedDocuments() {
  //   let loanTxnId = this.$route.query.id;
  //   this.$store.state.wemloLoader = true;
  //   let response = await Axios.post(
  //     BASE_API_URL + "compliance/downloadSelectedDocuments",
  //     {
  //       loanTxnId: loanTxnId,
  //       documents: this.selectedCompliance,
  //       status: status,
  //     },
  ////   );
  //   this.$store.state.wemloLoader = false;
  // }
  public async displaySample(documents, categoryName, fullView, documentId) {
    let status = this.getDocumentStatus(categoryName, documents);
    
    if (!Array.isArray(documents) || documents.length === 0) {
      this.$snotify.info("There is no document to view.");
      return null;
    }

    this.shownDocumentId = documentId;
    let file = documents[documents.length - 1].files;
    if (fullView && this.sourceType == "processor") {
      if(this.isCustomFile({categoryName})){
        let loanTxnId = this.$route.query.id;
        this.$store.state.wemloLoader = true;
        let response = await Axios.post(
          BASE_API_URL + "compliance/fetchOnDemandComplianceDocs",
          { loanTxnId: loanTxnId , customDoc : categoryName, sourceType : this.sourceType});
        this.$store.state.wemloLoader = false;
        if (response.status == 200 && response.data && response.data.file) {
          if(response.data.file['uploadedFile']){
            this.$refs.dfl["setFullModelView"](null, response.data.file['uploadedFile'], [], categoryName, status, 'file'); 
          }else if(response.data.file['contents']){
            this.$refs.dfl["setFullModelView"](null, response.data.file['contents'], [], categoryName, '', 'raw'); 
          }
        }
      }else{
        this.$refs.dfl["setFullModelView"](null, file, [], categoryName, status);
      }
    } else {
      //TODO: make api to get fileHistory// (category, loanTnxId) => last file History
      this.$refs.dfl["setInitialValuesForAttachmentsAndComments"](null, file);
    }
  }

  public saveBorrowerCompliancePackage() {
    this.$emit("saveCompliacePackage", this.complianceList);
  }

  public async approveRejectCompliance(status) {
    let loanTxnId = this.$route.query.id;
    this.$store.state.wemloLoader = true;
    let response = await Axios.post(
      BASE_API_URL + "compliance/updateComplianceDocStatus",
      {
        loanTxnId: loanTxnId,
        documentId: this.shownDocumentId,
        status: status,
      });
    if (response.status == 200 && response.data) {
      this.$modal.hide("documentViewFullcompianceList");
      this.$snotify.success("Status Updated Successfully.");
      this.$emit("fetchCompliance");
    } else {
      this.$snotify.error("Error in updating status.");
    }
    this.$store.state.wemloLoader = false;
  }

  public getLastUpdatedDate(documents) {
    if (!documents || !Array.isArray(documents)) {
      return null;
    }
    let file = documents[documents.length - 1];
    if (file && file.uploadedOn) {
      return file.uploadedOn || null;
    }
  }

  get resultQuery() {
    if (this.searchQuery) {
      return this.complianceList.filter((item) => {
        return this.searchQuery
          .toLowerCase()
          .split(" ")
          .every((v) => item.displayName.toLowerCase().includes(v));
      });
    } else {
      return this.complianceList;
    }
  }

  public selectCompliance(e, compliance) {
    this.$nextTick(() => {
      let checked = e.target.checked;
      if (!checked) {
        this.selectedCompliance = this.selectedCompliance.filter(
          (c) => c.categoryName != compliance.categoryName
        );
      } else {
        this.selectedCompliance.push(compliance);
      }
    });
  }

  public async fetchOnDemandComplianceDocs(docType) {
    try {
      let status = ''//this.getDocumentStatus(categoryName, documents);
      let loanTxnId = this.$route.query.id;
      this.$store.state.wemloLoader = true;
  
      let response = await Axios.post(
        BASE_API_URL + "compliance/fetchOnDemandComplianceDocs",
        { loanTxnId: loanTxnId , customDoc : docType, sourceType : this.sourceType});
      if (response.status == 200 && response.data && response.data.file) {
        if(response.data.file.documentId){
          this.shownDocumentId = response.data.file.documentId;
        }
        if (this.sourceType == "processor") {
          if(response.data.file['uploadedFile']){
            let status = this.getDocumentStatus(docType, response.data.file['uploadedFile']);
            this.$refs.dfl["setFullModelView"](null, response.data.file['uploadedFile'], [], docType, status, 'file');
          }else if(response.data.file['contents']){
            this.$refs.dfl["setFullModelView"](null, response.data.file['contents'], [], docType, status, 'raw'); 
          }
        }else{
          if(response.data.file['contents']){
            this.$refs.dfl["displayDocumentFromRawFile"]([response.data.file['contents']]); 
          }else{
            this.$refs.dfl["setInitialValuesForAttachmentsAndComments"](null, response.data.file['uploadedFile'], true, docType); 
          }
        }

      } else {
        this.$snotify.clear();
        this.$snotify.error("No Data Found");
      }
      this.$store.state.wemloLoader = false;
    } catch (error) {
      this.$store.state.wemloLoader = false;
    }
  }

  @Watch("complianceList", { immediate: true, deep: true })
  updateSelected() {
    if (
      this.sourceType == "brokerOnBoarding" ||
      this.sourceType == "editCompliance"
    ) {
      this.selectedCompliance = this.complianceList.filter(compliance => compliance.selected);
    }
  }

  /*************************************************************
   *          Checking required field of 1003 form             *
   *************************************************************/
  public async checkLosFormCompleted(restrictMoveToLead = false, callback = null, type = null) {
    try {
      // Reset this value before validation to allow open the required list more times
      this.isLosFieldMissing = false;
      this.exportCallback = null;

      let response = await Axios.post(
        BASE_API_URL + "loan/checkLosFormCompleted",
        { loanTxnId: this.$route.query.id });
      if (response.data.status == "error") {
        this.losRequiredList = response.data.errorList;
        this.isLosFieldMissing = true;
        if (callback && typeof callback === 'function') {
          this.exportCallback = () => {
            callback();
            this.isLosFieldMissing = false;
          }; 
        }
        return;
      }

      this.getCallback(type, callback);
    } catch (error) {
      console.log(error);
    }
  }

  getCallback(type, callback) {
    if (callback && typeof callback === 'function') {
      this.exportCallback = callback; 
      callback();
    }
  }

  public async closeRequiredFieldModel () {
    this.isLosFieldMissing = false;
    this.losRequiredList = null;
    this.$modal.hide('showLosRequiredField');
  }

  // ******************************Code Shifting from Wemlo Header of Los header*************************//
  public async getPrimaryBorrowerDetails(data) {
    let response: any = null;
    try {
      response = await Axios.post(
        BASE_API_URL + "common/getPrimaryBorrowerName",
        {
          loanTxnId: this.$route.query.id
        });
    } catch (error) {
      console.log(error);
    }
    saveAs(
      data,
      (response.data ? response.data : "los") +
        "_" +
        this.$route.query.id +
        ".zip"
    );
  }
  
  public async createLosPdf() {
    this.$store.commit('SET_WEMLO_LOADER', true);
    try {
      let response = await Axios.post(
        BASE_API_URL + "los/create1003PDF",
        {
          loanTxnId: this.$route.query.id
        },
        {
          responseType: "blob"
        }
      );
      await this.getPrimaryBorrowerDetails(response.data);
    } catch (error) {
      console.log(error);
    }
    this.$store.commit('SET_WEMLO_LOADER', false);
  }

  async createUlad() {
    this.$store.commit('SET_WEMLO_LOADER', true);
    try {
      let response = await Axios.post(
        BASE_API_URL + "mismo/createULADPDF",
        {
          loanTxnId: this.$route.query.id
        },
        {
          responseType: "blob"
        }
      );
      await this.getPrimaryBorrowerDetails(response.data);
    } catch (error) {
      console.log(error);
    }
    this.$store.commit('SET_WEMLO_LOADER', false);
  }

  async getEnumerationType() {
    const loanTxnId = this.$route.query.id;
    const { data: { enumerationType } } = await Axios.post(
      BASE_API_URL + 'los/getEnumerationType', { loanTxnId }
    );

    return enumerationType;
  }

  async mounted() {
    this.isMismo = (await this.getEnumerationType()) === 'mismo';
  }

  redirectToLosBorrowerTab() {
    const loanTxnId = this.$route.query.id;
    this.$router.push(`${this.$getCurrentUserTypePath('current-urla')}?id=${loanTxnId}#PersonalInfo`);
  }
}
